interface IsFQDNOptions {
  requireTld?: boolean;
  allowUnderscores?: boolean;
  allowTrailingDot?: boolean;
  allowNumericTld?: boolean;
  allowWildcard?: boolean;
}

export default function isFQDN(
  str: string | undefined | null,
  {
    requireTld = true,
    allowUnderscores = false,
    allowTrailingDot = false,
    allowWildcard = false,
    allowNumericTld = false,
  }: IsFQDNOptions = {},
): boolean {
  if (!str) {
    return false;
  }

  /* Remove the optional trailing dot before checking validity */
  if (allowTrailingDot && str[str.length - 1] === '.') {
    str = str.substring(0, str.length - 1);
  }

  /* Remove the optional wildcard before checking validity */
  if (allowWildcard === true && str.indexOf('*.') === 0) {
    str = str.substring(2);
  }

  const parts = str.split('.');
  const tld = parts[parts.length - 1];

  if (requireTld) {
    // disallow fqdns without tld
    if (parts.length < 2) {
      return false;
    }

    if (!/^([a-z\u00A1-\u00A8\u00AA-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}|xn[a-z\d-]{2,})$/i.test(tld)) {
      return false;
    }

    // disallow spaces
    if (/\s/.test(tld)) {
      return false;
    }
  }

  // reject numeric TLDs
  if (!allowNumericTld && /^\d+$/.test(tld)) {
    return false;
  }

  return parts.every((part) => {
    if (part.length > 63) {
      return false;
    }

    if (!/^[a-z_\u00a1-\uffff0-9-]+$/i.test(part)) {
      return false;
    }

    // disallow full-width chars
    if (/[\uff01-\uff5e]/.test(part)) {
      return false;
    }

    // disallow parts starting or ending with hyphen
    if (/^-|-$/.test(part)) {
      return false;
    }

    if (!allowUnderscores && /_/.test(part)) {
      return false;
    }

    return true;
  });
}
